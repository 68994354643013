import LogoEssentiaGroup from './assets/img/logo-essentia-group.svg'
import EssentiaPharma from './assets/img/essentia-pharma.svg'
import EssentialNutrition from './assets/img/essential-nutrition.svg'
import Noorskin from './assets/img/noorskin.svg'
import BeGenerous from './assets/img/be-generous.svg'
import EasyHealth from './assets/img/easy-health.svg'
import LaboratorioPharma from './assets/img/laboratorio-essentia-pharma.jpg'
import BrainstormEssentialNutrition from './assets/img/brainstorm-coffee-essential-nutrition.jpg'
import NaturalLightNoorskin from './assets/img/natural-light-noorskin.jpg'
import Omega3BeGenerous from './assets/img/omega-3-be-generous.jpg'
import UsuarioEasyHealth from './assets/img/usuario-easy-health.jpg'
import LogoGroup from './assets/img/logo-essentia-group-white.svg'
import Experience from './assets/img/essentia-experience.svg'
import ExperienceP from './assets/img/essentia-experience-p.svg'
import EssentiaExperienceAmbiente from './assets/img/essentia-experience-ambiente.jpg'

import MobileLaboratorioPharma from './assets/img/mobile/laboratorio-essentia-pharma.jpg'
import MobileBrainstormEssentialNutrition from './assets/img/mobile/brainstorm-coffee-essential-nutrition.jpg'
import MobileNaturalLightNoorskin from './assets/img/mobile/natural-light-noorskin.jpg'
import MobileOmega3BeGenerous from './assets/img/mobile/omega-3-be-generous.jpg'
import MobileUsuarioEasyHealth from './assets/img/mobile/usuario-easy-health.jpg'


export const desktopImages = {
    LogoEssentiaGroup,
    EssentiaPharma,
    EssentialNutrition,
    Noorskin,
    BeGenerous,
    EasyHealth,
    LaboratorioPharma,
    BrainstormEssentialNutrition,
    NaturalLightNoorskin,
    Omega3BeGenerous,
    UsuarioEasyHealth,
    LogoGroup,
    Experience,
    Experience,
    ExperienceP,
    EssentiaExperienceAmbiente
};

export const mobileImages = {
    LaboratorioPharma: MobileLaboratorioPharma,
    BrainstormEssentialNutrition: MobileBrainstormEssentialNutrition,
    NaturalLightNoorskin: MobileNaturalLightNoorskin,
    Omega3BeGenerous: MobileOmega3BeGenerous,
    UsuarioEasyHealth: MobileUsuarioEasyHealth
};
