import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Conteudo.css";
import { desktopImages, mobileImages } from "../images";

const Conteudo = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.3, // Mostrar 2 logos e um pedaço da terceira
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: "unslick", // Desativar carrossel em telas maiores
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          margin: 0,
          variableWidth: true,
        },
      },
    ],
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getImage = (name) =>
    isMobile ? mobileImages[name] : desktopImages[name];

  const smoothScrollTo = (targetPosition, duration) => {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const handleAnchorClick = (id, event) => {
    event.preventDefault(); // Previne o comportamento padrão do link

    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop;
      smoothScrollTo(offsetTop, 1000); // Ajuste a duração conforme necessário
    }
  };

  return (
    <div className="conteudo">
      <div className="bg-image">
        <div className="container">
          <img
            className="logo-group"
            src={desktopImages.LogoEssentiaGroup}
            alt="Logo Essentia Group"
          />
          <p className="title">{t("title")}</p>
          <p className="p-1">{t("p-1")}</p>
          <p className="p-2">{t("p-2")}</p>
          <p className="p-3">{t("p-3")}</p>
          <p className="p-4">{t("p-4")}</p>
          <div className="logos-grupo">
            <a
              onClick={(event) => handleAnchorClick("essentia-pharma", event)}
              href="#essentia-pharma"
            >
              <img src={desktopImages.EssentiaPharma} alt="Essentia Pharma" />
            </a>
            <a
              onClick={(event) =>
                handleAnchorClick("essential-nutrition", event)
              }
              href="#essential-nutrition"
            >
              <img
                src={desktopImages.EssentialNutrition}
                alt="Essential Nutrition"
              />
            </a>
            <a
              onClick={(event) => handleAnchorClick("noorskin", event)}
              href="#noorskin"
            >
              <img
                className="logo-noorskin"
                src={desktopImages.Noorskin}
                alt="Noorskin"
              />
            </a>
            <a
              onClick={(event) => handleAnchorClick("be-generous", event)}
              href="#be-generous"
            >
              <img
                width="120px"
                height="60px"
                src={desktopImages.BeGenerous}
                alt="Be Generous"
              />
            </a>
            <a
              onClick={(event) => handleAnchorClick("experience", event)}
              href="#experience"
            >
              <img
                width="88px"
                src={desktopImages.ExperienceP}
                alt="Essentia Experience"
              />
            </a>
          </div>
          <Slider {...settings} className="mobile-logos-grupo">
            <div>
              <a
                onClick={(event) => handleAnchorClick("essentia-pharma", event)}
                href="#essentia-pharma"
              >
                <img src={desktopImages.EssentiaPharma} alt="Essentia Pharma" />
              </a>
            </div>
            <div>
              <a
                onClick={(event) =>
                  handleAnchorClick("essential-nutrition", event)
                }
                href="#essential-nutrition"
              >
                <img
                  src={desktopImages.EssentialNutrition}
                  alt="Essential Nutrition"
                />
              </a>
            </div>
            <div>
              <a
                onClick={(event) => handleAnchorClick("noorskin", event)}
                href="#noorskin"
              >
                <img
                  className="logo-noorskin"
                  src={desktopImages.Noorskin}
                  alt="Noorskin"
                />
              </a>
            </div>
            <div>
              <a
                onClick={(event) => handleAnchorClick("be-generous", event)}
                href="#be-generous"
              >
                <img
                  width="120px"
                  height="60px"
                  src={desktopImages.BeGenerous}
                  alt="Be Generous"
                />
              </a>
            </div>
            <div>
              <a
                onClick={(event) => handleAnchorClick("experience", event)}
                href="#experience"
              >
                <img
                  width="88px"
                  src={desktopImages.ExperienceP}
                  alt="Essentia Experience"
                />
              </a>
            </div>
          </Slider>
        </div>
      </div>
      <div className="bg-empresas">
        <div className="container">
          <div className="d-flex" id="essentia-pharma">
            <div className="imagem">
              <img
                src={getImage("LaboratorioPharma")}
                alt="Mulheres trabalhando na fármacia de manipulação Essentia Pharma"
              />
            </div>
            <div className="texto">
              <img
                src={desktopImages.EssentiaPharma}
                alt="Logo Essentia Pharma"
              />
              <p
                className="p-5"
                dangerouslySetInnerHTML={{ __html: t("p-5") }}
              ></p>
              <p className="p-6">{t("p-6")}</p>
              <a
                target="_blank"
                rel="noreferrer"
                className="site"
                href="https://essentia.com.br/"
              >
                {t("site")}
              </a>
            </div>
          </div>
          <div
            className="d-flex gap-58 column-reverse"
            id="essential-nutrition"
          >
            <div className="texto">
              <img
                src={desktopImages.EssentialNutrition}
                alt="Logo Essential Nutrition"
              />
              <p
                className="p-8"
                dangerouslySetInnerHTML={{ __html: t("p-8") }}
              ></p>
              <p className="p-9">{t("p-9")}</p>
              <a
                target="_blank"
                rel="noreferrer"
                className="site"
                href="https://essentialnutrition.com.br/"
              >
                {t("site")}
              </a>
            </div>
            <div className="imagem">
              <img
                src={getImage("BrainstormEssentialNutrition")}
                alt="Mulher segurando pote de Brainstorm da Essential Nutrition"
              />
            </div>
          </div>
          <div className="d-flex noorskin" id="noorskin">
            <div className="imagem">
              <img
                src={getImage("NaturalLightNoorskin")}
                alt="Mulher segurando produto Natural Light da Noorskin"
              />
            </div>
            <div className="texto">
              <img
                className="logo-noorskin"
                src={desktopImages.Noorskin}
                alt="Logo Noorskin"
              />
              <p
                className="p-11"
                dangerouslySetInnerHTML={{ __html: t("p-11") }}
              ></p>
              <p className="p-12">{t("p-12")}</p>
              <a
                target="_blank"
                rel="noreferrer"
                className="site"
                href="https://www.noorskin.com.br/"
              >
                {t("site")}
              </a>
            </div>
          </div>
          <div className="d-flex be-generous column-reverse" id="be-generous">
            <div className="texto">
              <img
                width="120px"
                height="60px"
                src={desktopImages.BeGenerous}
                alt="Logo Be Generous"
              />
              <p
                className="p-14"
                dangerouslySetInnerHTML={{ __html: t("p-14") }}
              ></p>
              <p className="p-15">{t("p-15")}</p>
              <a
                target="_blank"
                rel="noreferrer"
                className="site"
                href="https://begenerous.com.br/"
              >
                {t("site")}
              </a>
            </div>
            <div className="imagem">
              <img
                src={getImage("Omega3BeGenerous")}
                alt="Mulher grávida segurando doação de omega 3 do projeto Be Generous"
              />
            </div>
          </div>
          <div className="d-flex experience" id="experience">
            <div className="imagem">
              <img src={getImage("EssentiaExperienceAmbiente")} alt="" />
            </div>
            <div className="texto">
              <img
                className="logo-experience"
                src={desktopImages.Experience}
                alt="Logo Experience"
              />
              <p
                className="p-16"
                dangerouslySetInnerHTML={{ __html: t("p-16") }}
              ></p>
              <p
                className="p-17"
                dangerouslySetInnerHTML={{ __html: t("p-17") }}
              ></p>
              <a rel="noreferrer" className="site" href="../essentiaexperience">
                {t("site")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conteudo;
